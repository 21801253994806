import $ from 'jquery';
import jQuery from 'jquery';

$(function()
	{
	if (Foundation.MediaQuery.atLeast('medium'))
		$(window).bind('scroll', function(e)
			{ // parallax
			var scrolledY = $(window).scrollTop();
			$('#header .background').css('top', '+' + ((scrolledY*0.25)) + 'px');
			});
	});
